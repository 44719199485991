export const SquareStackIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.81238 5.19992V3.79992C5.81238 3.54219 6.01137 3.33325 6.25683 3.33325H11.5902C11.8357 3.33325 12.0347 3.54219 12.0347 3.79992V10.3333C12.0347 10.591 11.8357 10.7999 11.5902 10.7999H10.2569V12.1995C10.2569 12.4575 10.0569 12.6666 9.8094 12.6666H4.48208C4.23493 12.6666 4.03467 12.4591 4.03467 12.1995L4.03582 5.66699C4.03587 5.40904 4.23584 5.19992 4.4833 5.19992H5.81238ZM4.92464 6.13325L4.92365 11.7333H9.36797V6.13325H4.92464ZM6.70128 5.19992H10.2569V9.86659H11.1458V4.26659H6.70128V5.19992Z"
      fill="#BEBEBE"
    />
  </svg>
)
