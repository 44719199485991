export const ErrorIcon = () => {
  return (
    <svg
      width="50"
      height="45"
      viewBox="0 0 50 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9291 3.78261C23.294 1.4058 26.706 1.4058 28.0709 3.78261L47.5196 37.6522C48.8845 40.029 47.1784 43 44.4488 43H5.55121C2.82156 43 1.11553 40.029 2.48035 37.6522L21.9291 3.78261Z"
        fill="#392222"
      />
      <path
        d="M25 16.5V28.9783M25 34.087V34.6812M5.55121 43H44.4488C47.1784 43 48.8845 40.029 47.5196 37.6522L28.0709 3.78261C26.706 1.4058 23.294 1.4058 21.9291 3.78261L2.48035 37.6522C1.11553 40.029 2.82156 43 5.55121 43Z"
        stroke="#DA3434"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
