import { ComponentProps } from "react"

export const EtherscanLogo = (props: ComponentProps<"svg">) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.87037 11.213C6.87038 11.1164 6.89075 11.0207 6.93029 10.9315C6.96983 10.8423 7.02777 10.7613 7.10078 10.6931C7.17381 10.625 7.26044 10.571 7.35575 10.5344C7.45106 10.4977 7.55315 10.4791 7.65616 10.4795L8.959 10.4835C9.16671 10.4835 9.36592 10.5609 9.5128 10.6987C9.65969 10.8365 9.74221 11.0233 9.74221 11.2181V15.8387C9.88888 15.7979 10.0772 15.7544 10.2834 15.7088C10.4265 15.6773 10.5541 15.6013 10.6455 15.4932C10.7368 15.385 10.7865 15.2511 10.7865 15.1132V9.38157C10.7865 9.18672 10.869 8.99986 11.0158 8.86207C11.1627 8.72428 11.3619 8.64684 11.5697 8.6468H12.8751C13.0828 8.64684 13.282 8.72428 13.4289 8.86207C13.5758 8.99986 13.6583 9.18672 13.6583 9.38157V14.7011C13.6583 14.7011 13.9851 14.577 14.3035 14.451C14.4218 14.4041 14.5227 14.3255 14.5937 14.2252C14.6647 14.1248 14.7026 14.0071 14.7027 13.8866V7.545C14.7027 7.35019 14.7852 7.16336 14.932 7.0256C15.0789 6.88782 15.278 6.81042 15.4857 6.81038H16.7912C16.9989 6.81038 17.1981 6.88778 17.345 7.02554C17.4919 7.16331 17.5744 7.35017 17.5744 7.545V12.7672C18.7061 11.9979 19.8531 11.0726 20.7632 9.96C20.8953 9.7985 20.9827 9.60887 21.0176 9.408C21.0524 9.20715 21.0338 9.0013 20.9633 8.80883C20.5419 7.67187 19.8723 6.62991 18.997 5.74965C18.1218 4.86938 17.0602 4.17018 15.8803 3.69681C14.7004 3.22344 13.4282 2.98632 12.145 3.00061C10.8618 3.0149 9.59587 3.2803 8.42831 3.77982C7.26073 4.27934 6.21719 5.00198 5.36446 5.9015C4.51174 6.80102 3.86861 7.85759 3.47622 9.00363C3.08384 10.1497 2.95085 11.3599 3.08576 12.5569C3.22067 13.7539 3.62051 14.9113 4.25969 15.9551C4.37104 16.1351 4.53485 16.2816 4.73189 16.3774C4.92892 16.4731 5.15099 16.5142 5.37203 16.4957C5.61895 16.4753 5.92638 16.4464 6.2919 16.4062C6.45102 16.3892 6.59793 16.318 6.70462 16.206C6.81134 16.094 6.87038 15.9491 6.87052 15.7989L6.87037 11.213Z"
      fill="white"
    />
    <path
      d="M6.03467 19.3886C7.44366 20.3026 9.10878 20.8512 10.8458 20.9738C12.5828 21.0962 14.324 20.788 15.8766 20.0828C17.4293 19.3776 18.7329 18.3032 19.6433 16.9785C20.5535 15.6537 21.0351 14.1301 21.0347 12.5764C21.0347 12.3825 21.0246 12.1907 21.0101 12C17.5594 16.5892 11.1879 18.7346 6.03496 19.3879"
      fill="#8B8B8B"
    />
  </svg>
)
