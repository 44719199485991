import _ from "lodash";
import { formatBigIntToString } from "src/utils/formatBigIntToString";
import { Address, useContractReads } from "wagmi";
import MultiRewardsAbi from "../abis/MultiRewards.json"

export const createContractRead = (
  address: Address,
  account: Address,
  rewardsToken: Address,
) => ({
  address,
  abi: MultiRewardsAbi,
  functionName: "earned",
  args: [account, rewardsToken],
})

export const useUserRewardsBalance = (
  address: Address | undefined,
  account: Address | undefined,
  rewardsToken: Address | undefined,
) => {
  let contracts: any[] = []

  if (address && account && rewardsToken) {
    contracts.push(createContractRead(address, account, rewardsToken))
  }

  const isEnabled = !_.isEmpty(contracts);

  const { data } = useContractReads({
    contracts,
    enabled: isEnabled,
  })

  const rawBalance = data?.[0]?.result as bigint

  const rewards = {
    stakePoolAddress: address,
    balance: rawBalance || undefined,
    fullParsedBalance: formatBigIntToString(rawBalance, 18),
  }

  return rewards
}
