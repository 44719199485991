export const CircleStackIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 20.8333C8.33333 21.3557 9.10168 22.264 10.8841 23.1552C13.1901 24.3082 16.4613 25 20 25C23.5387 25 26.8098 24.3082 29.1158 23.1552C30.8983 22.264 31.6667 21.3557 31.6667 20.8333V17.2145C28.9167 18.9137 24.7122 20 20 20C15.2879 20 11.0833 18.9137 8.33333 17.2145V20.8333ZM31.6667 25.5478C28.9167 27.247 24.7122 28.3333 20 28.3333C15.2879 28.3333 11.0833 27.247 8.33333 25.5478V29.1667C8.33333 29.689 9.10168 30.5973 10.8841 31.4885C13.1901 32.6415 16.4613 33.3333 20 33.3333C23.5387 33.3333 26.8098 32.6415 29.1158 31.4885C30.8983 30.5973 31.6667 29.689 31.6667 29.1667V25.5478ZM5 29.1667V12.5C5 8.35787 11.7157 5 20 5C28.2843 5 35 8.35787 35 12.5V29.1667C35 33.3088 28.2843 36.6667 20 36.6667C11.7157 36.6667 5 33.3088 5 29.1667ZM20 16.6667C23.5387 16.6667 26.8098 15.9749 29.1158 14.8219C30.8983 13.9307 31.6667 13.0224 31.6667 12.5C31.6667 11.9776 30.8983 11.0693 29.1158 10.1781C26.8098 9.02513 23.5387 8.33333 20 8.33333C16.4613 8.33333 13.1901 9.02513 10.8841 10.1781C9.10168 11.0693 8.33333 11.9776 8.33333 12.5C8.33333 13.0224 9.10168 13.9307 10.8841 14.8219C13.1901 15.9749 16.4613 16.6667 20 16.6667Z"
      fill="#BEBEBE"
    />
  </svg>
)
